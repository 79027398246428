<template>
  <div>
    <br />
    <div class="d-flex justify-content-center">
      <span
        class="p-2 fs-5"
        :class="sale.validated ? 'text-success' : 'text-warning'"
      >
        <span v-if="sale.validated" class="fs-5">
          <i class="bi bi-check2"></i>
        </span>
        Facture Référence Nº :
        <span class="fw-bold fs-5">
          {{ $route.params.reference }}
        </span>

        <span class="fw-bold text-dark fs-5">
          {{ sale.date }}
        </span>
      </span>
    </div>
    <div class="d-flex justify-content-center align-items-center p-0">
      <div class="col-auto row">
        <div v-if="sale.reference && $userRole == 'Admin'" class="col-auto p-1">
          <button
            @click="recalculationPrices(sale.reference)"
            class="btn border"
          >
            <i class="bi bi-coin text-secondary fs-4"></i> Recalculer
          </button>
        </div>

        <div v-if="!sale.validated && $userRole == 'Admin'" class="col-auto p-1">
          <button
            @click="
              $router.push({
                name: 'sales-validated',
                params: {
                  reference: sale.reference,
                },
              })
            "
            class="btn border text-success border-success"
          >
            <i class="bi bi-check2-square text-success fs-4"></i> Valider
          </button>
        </div>

        <div v-if="sale.reference && $userRole == 'Admin'" class="col-auto p-1">
          <button
            @click="
              $router.push({
                name: 'sales-edit',
                params: { reference: sale.reference },
              })
            "
            class="btn border"
          >
            <i class="bi bi-pencil-square text-secondary fs-4"></i> Modifier
          </button>
        </div>

        <div v-if="sale.reference && $userRole == 'Admin'" class="col-auto p-1">
          <button
            @click="remove(sale.reference)"
            class="btn border border-danger text-danger"
          >
            <i class="bi bi-archive-fill text-danger fs-4"></i> Supprimer
          </button>
        </div>

        <div v-if="sale.reference && $userRole == 'Admin'" class="col-auto p-1">
          <button
            @click="
              $router.push({
                name: 'invoices-create',
                params: { reference: sale.reference },
              })
            "
            class="btn border border-primary text-primary"
          >
            <i class="bi bi-printer text-primary fs-4"></i> Facture
          </button>

          <!-- <button
            @click="printInvoice(sale.reference)"
            class="btn border border-primary text-primary"
          >
            <i class="bi bi-printer text-primary fs-4"></i> Facture
          </button> -->
        </div>

        <div v-if="sale.reference" class="col-auto p-1">
          <button @click="print(sale.reference)" class="btn border">
            <i class="bi bi-printer text-secondary fs-4"></i> Imprimer BL
          </button>
        </div>

        <div v-if="sale.reference" class="col-auto p-1">
          <button @click="printQuotation(sale.reference)" class="btn border">
            <i class="bi bi-printer text-secondary fs-4"></i> Imprimer BL
            (Quantités)
          </button>
        </div>

        <div class="col-auto p-1">
          <button @click="refresh()" class="btn border">
            <i class="bi bi-arrow-clockwise text-secondary fs-4"></i> Actualiser
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row align-items-stretch" id="printMe">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Client</div>
          <div class="col p-1 fw-bold">
            <span
              v-if="sale.customer"
              :class="
                sale.customer.fullName.includes('Bloqué') ? 'text-danger' : ''
              "
            >
              {{ sale.customer.fullName }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nº de Téléphone</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer">
              {{ sale.customer.phone }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nom Commercial (Société)</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer">
              {{ sale.customer.society }}
            </span>
          </div>
        </div>
      </div>
      <!-- ***** -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Quantité totale</div>
          <div v-if="sale.totalQuantity" class="col p-1 fw-bold">
            {{ sale.totalQuantity }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TTC</div>
          <div v-if="sale.totalPriceTTC" class="col p-1 fw-bold">
            {{ sale.totalPriceTTC | toFixedWithSpace }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total HT</div>
          <div v-if="sale.totalPriceHT" class="col p-1 fw-bold">
            {{ sale.totalPriceHT | toFixedWithSpace }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TVA</div>
          <div v-if="sale.totalPriceTVA" class="col p-1 fw-bold">
            {{ sale.totalPriceTVA | toFixedWithSpace }}
          </div>
        </div>
      </div>
      <!-- paiments -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Total Payé :</div>
          <div v-if="sale.amountPaid" class="col p-1 fw-bold">
            {{ sale.amountPaid | toFixedWithSpace }}
          </div>
          <div v-else class="col p-1 fw-bold text-danger">----------</div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Impayé :</div>
          <div v-if="sale.amountUnpaid" class="col p-1 fw-bold">
            {{ sale.amountUnpaid | toFixedWithSpace }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Échéance :</div>
          <div class="col p-1 fw-bold">
            {{ sale.deadline }}
          </div>
        </div>
      </div>
    </div>

    <br />
    <div class="row align-items-stretch">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">État de livraison</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Livré par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Livré le :</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer"> - </span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Vérifié (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer"> - </span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Validé (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.validated"> Validée </span>
            <span v-else class="bg-warning rounded p-1"> En Attente </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer"> - </span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-2">Remarque :</div>
      <div class="col fw-bold">
        {{ sale.remark }}
      </div>
    </div>
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom</th>
          <th scope="col">Quantité</th>
          <th scope="col">Prix U (TTC)</th>
          <th scope="col">M. Total</th>
          <th scope="col">Stock</th>
          <th scope="col">Remarque</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in sale.sale_items" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ item.item_reference }}
          </td>
          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td>{{ item.quantity }}</td>
          <td class="fw-bold">
            {{ item.priceTTC | toFixedWithSpace }}
          </td>
          <td class="fw-bold">
            {{ item.totalPriceTTC | toFixedWithSpace }}
          </td>

          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }}
            </span>
          </td>

          <td>
            {{ item.remark }}
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col-1">Nº</th>
          <th scope="col-2">Référence</th>
          <th scope="col-2">Date</th>
          <th scope="col-2">Mode</th>
          <th scope="col-2">M. Payé</th>
          <th scope="col-2">M. à Régler</th>
          <th scope="col-2">Créer par</th>
          <th scope="col">Remarque</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in sale.payments" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ payment.reference }}
          </td>

          <td>{{ payment.date }}</td>

          <td class="text-warning">
            <span>
              {{ payment.paymentMethod }}
            </span>
          </td>
          <td class="text-success">
            <span v-if="payment.amount >= 0">
              {{ payment.amount | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-danger">
            <span v-if="payment.remainingAmount >= 0">
              {{ payment.remainingAmount | toFixedWithSpace }}
            </span>
          </td>
          <td>
            <span v-if="payment.user">
              {{ payment.user.name }}
            </span>
          </td>
          <td>
            <span>
              {{ payment.remark }}
            </span>
          </td>
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'payments-details',
                  params: { reference: payment.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("sale/show", this.$route.params.reference);
  },
  computed: {
    ...mapGetters("sale", {
      sale: "getSale",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("sale/show", this.$route.params.reference);
    },
    async recalculationPrices(reference) {
      await this.$store.dispatch("sale/recalculationPrices", reference);
    },
    async print(reference) {
      this.$store.dispatch("sale/print", reference);
    },
    async printQuotation(reference) {
      this.$store.dispatch("sale/printQuotation", reference);
    },
    async printInvoice(reference) {
      this.$store.dispatch("sale/printInvoice", reference);
    },
    async remove(reference) {
      let data = reference;
      await this.$confirm({
        message: "Vous voulez supprimer la facture  " + data,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("sale/destroy", data);
          }
        },
      });
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
